import React, { useState }  from 'react';
import './home.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Footer from '../Components/Footer/footer.jsx';
import Complace from '../Components/Complace/complace.jsx';

function Home(){
    return <div>
      <Navbar/>
      <section id="banner">
        <div className="container">

            <div className="row">
                <div className="col-lg-6">
                    <h1>Simplifique o rumo de seus negócios.</h1>
                    <p/>
                    <p/>
                    <h4>Criamos soluções para o gerenciamento de ambientes de TI de forma automatizada.</h4>
                </div>
                <div className="col-lg-6">
                    <img src="Images/foto1.png" />
                </div>
            </div>

         </div>
       </section>

       <div className="row" id="planos">

            <div className="col-lg-4">
               <div className="card text-white bg-dark mb-3">
                  <img class="card-img-top" src="Images/site-robusto.jpg" alt="key 42"/>
                  <div className="card-body">
                      <h5 className="card-title">Site Robusto</h5>
                      <p className="card-text">Mantenha-se sempre a frente com as tendências de novas tecnologias e criptografias.</p>
                  </div>
               </div>
            </div>

            <div className="col-lg-4">
               <div className="card text-white bg-dark mb-3">
               <img class="card-img-top" src="Images/aplicativos.jpg" alt="key 42"/>
                  <div className="card-body">
                      <h5 className="card-title">Aplicativos</h5>
                      <p className="card-text">Tenha o controle se seus negócios ou times na palma da mão. O olho do patrão que engorda o gado</p>
                  </div>
               </div>
            </div>

            <div className="col-lg-4">
               <div className="card text-white bg-dark mb-3">
               <img class="card-img-top" src="Images/inovacao_2.jpg" alt="key 42"/>
                  <div className="card-body">
                      <h5 className="card-title">Inovações</h5>
                      <p className="card-text">Amplie seu atendimento de forma rápida e acertiva. Respostas diretas e sem perder tempo.</p>
                  </div>
               </div>
            </div>


        </div>

           <Footer/>
           <Complace/>
    </div>
  }

export default Home;