import React from 'react';
import './footer.css';


function Footer(){
  var ano = new Date().getFullYear(); 

    return <section id="footer">    
     <div className="container" id="linha">
             <div className="row">

             <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-heart fa-5x"></i>
                    <h3>Fidelidade</h3>                    
                    <p>Amamos o que fazemos, cuidando de nossos clientes com respeito e admiração.</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-globe-americas fa-5x"></i>
                    <h3>Ecossistema</h3>
                    <p>Preservamos hoje para nossa geração de amanhã.</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-columns fa-5x"></i>
                    <h3>Multi-plataforma</h3>
                    <p>Soluções híbridas, consistentes e inovadoras de verdade.</p>
                </div>

             </div>
      </div>
</section>

}

export default Footer;