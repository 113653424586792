import React, { useState, useEffect } from 'react';
import './cliente.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Footer from '../Components/Footer/footer.jsx';
import Complace from '../Components/Complace/complace.jsx';

function Cliente(){


    return <div>
      <Navbar/>

      <div className='container-fluid titulo'>

        <div className='row' id="img_center">
          <div className='col-3'>
            <img src="/Images/alphamais.png" alt="key 42" height="100"/>
          </div>

          <div className='col-3'>
            <img src="/Images/cptm.png" alt="key 42" height="50"/>
          </div>

          <div className='col-3'>
            <img src="/Images/mayor.png" alt="key 42" height="75"/>
          </div>

          <div className='col-3'>
            <img src="/Images/senai.png" alt="key 42" height="50"/>
          </div>
        </div>

        <div className='row' id="img_center">
          <div className='col-3'>
            <img src="/Images/vli.png" alt="key 42" height="100"/>
          </div>

          <div className='col-3'>
            <img src="/Images/xpoynt.png" alt="key 42" height="100"/>
          </div>

          <div className='col-3'>
            <img src="/Images/chv_alto_lapa.png" alt="key 42" height="100"/>
          </div>

          <div className='col-3'>
            <img src="/Images/fph.jpg" alt="key 42" height="100"/>
          </div>
        </div>

        <div className='row' id="img_center">
          <div className='col-3'>
            <img src="/Images/bf-facilities.png" alt="key 42" height="100"/>
          </div>

          <div className='col-3'>
            <img src="/Images/via-mobilidade.png" alt="key 42" height="50"/>
          </div>

          <div className='col-3'>
            <img src="/Images/saintnemet.png" alt="key 42" height="100"/>
          </div>

          <div className='col-3'>
            <img src="/Images/vilailheus.png" alt="key 42" height="100"/>
          </div>
        </div>

      </div>

      <Footer/>
      <Complace/>
    </div>
  }
    
  
export default Cliente;