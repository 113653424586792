import React from 'react';
import { Link } from 'react-router-dom';

import './navbar.css';

function Navbar(){

    return <nav className="navbar fixed-top navbar-expand-md navbar-dark">
    <div className='container-fluid'>
            <a className="navbar-brand" href="/#index.html"> 
              <img src="/Images/logo.png" alt="key 42" height="75"/>
            </a>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <Link to="/" className="nav-link" aria-current="page" >Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/app/cliente" className="nav-link" aria-current="page" >Clientes</Link>
                    </li>
                </ul>
            </div>
    </div>
 </nav>;
}

export default Navbar;
