import React from 'react';
import { Link } from 'react-router-dom';
import './intelectual.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function Intelectual(){
    return <div>
      <Navbar/>
      <section id="comp">
        <div className="container">
          <p id="justificar"> 
          <p className='fs-1 fw-bold text-center'> PROPRIEDADE INTELECTUAL</p> 

           <p className='fw-normal'> A Editora Alphaville se dedica a proporcionar aos clientes a compra de produtos e serviços, expectando a seriedade negocial, buscando o respeito mútuo e obtendo a experiência de compras incríveis. A Editora Alphaville não permite ofertas que violam os direitos de propriedade intelectual de marcas ou outros proprietários de direitos.</p>
          </p>
          <Link to="/app/intelectualcomp"><a>Ler mais...</a></Link>

        </div>
       </section>

       <div className="row" id="planos">

       </div>
       <Complace/>
    </div>
  }

export default Intelectual;